<template>
    <div class="bg-container" :style="{ backgroundColor: options.wallBackgroundColor }">
        <div class="main-container" :style="{ backgroundColor: options.backgroundColor }">
            <img class="logo" :src="options.logo" />
            <div class="content-container">
                <p :style="{color: options.textColor}">
                    Your account is succesfully deleted.
                </p>
            </div>
        </div>
    </div>
</template>
  
<script>
/*
采用3db配色作为默认配色
*/
export default {
    props: {
        /*
        wallBackgroundColor: 背景墙的背景颜色,
        backgroundColor: 内容的背景颜色,
        logo: logo地址,
        textColor: 提示文本的颜色,
        */
        options: Object
    },
    mounted() {
        const redirect_uri = this.$route.query.redirect_uri;
        if (redirect_uri != undefined) {
            setTimeout(() => {
                this.$router.push({
                    path: redirect_uri
                });
            }, 3000);
        }
    }
}
</script>
  
<style lang="scss" scoped>
.bg-container {
    background: #f8f8f8;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100%;
    display: flex
}

.main-container {
    background: #f4f3ef;
    max-width: 480px;
    min-width: 240px;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.logo {
    width: 100%;
    vertical-align: middle; // 取消图片底部留白
}

.content-container {
    // background-color: red;
    padding: 1.75rem;
    // border: 1px solid red;
    height: 100%;

    /* 弹性布局 */
    display: flex;
    /* 将div容器中的元素水平居中 */
    justify-content: center;
    /* 将div容器中的元素垂直居中 */
    align-items: center;
    /* 可选：将div容器中的文本水平居中 */
    text-align: center;

    p {
        margin: 10rem 0;
        padding: 0;
        width: 100%;
        font-family: SFProText-Bold;
        font-size: 1rem;
        color: #7e91ad;
        letter-spacing: -0.0106rem;
        text-align: center;
        line-height: 1.625rem;
        font-weight: 700;
    }
}
</style>