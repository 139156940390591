<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'brelhome',
        themeColor: '#81142d',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/brelhome/img/logo.png'), 
        inputStyle: 'round-border',
        inputBorderColor: '#979797',
        cancelBtnBackgroundColor: "#81142d",
        cancelBtnBackgroundColorHover: '#b37281',
      }
    };
  }
}
</script>