<template>
  <DelAccountSuccess :options=viewOptions></DelAccountSuccess>
</template>

<script>
import DelAccountSuccess from '@/components/DelAccountSuccess'

export default {
  components: {
    DelAccountSuccess,
  },
  data() {
    return {
      viewOptions: {
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/came/img/logo.png'), 
      }
    };
  }
}
</script>