<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'wynmotion',
        themeColor: '#efb1c9',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/wynmotion/img/logo.png'), 
        inputStyle: 'round-border',
        cancelBtnBackgroundColor: "#efb1c9",
        cancelBtnBackgroundColorHover: '#f4c8d9',
      }
    };
  }
}
</script>