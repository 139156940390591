<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'connector',
        themeColor: '#438af3',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/connector/img/logo.png'), 
        inputStyle: 'round-border',
        cancelBtnBackgroundColor: "#438af3",
        cancelBtnBackgroundColorHover: '#8eb9f8',
      }
    };
  }
}
</script>