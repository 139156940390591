<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'motion',
        appCode: '73fe1093-a535-4690-93b5-27d0767cc516',
        themeColor: '#000000',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/motion/img/logo.png'), 
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#000000",
        loginBtnBackgroundColorHover: '#666666',
        loginBtnBackgroundColorDisabled: '#cccccc',
      }
    };
  }
}
</script>