<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'brelmatter',
        appCode: 'e2c0f218-20fd-4094-9434-5d26c81e1d0d',
        themeColor: '#791b2c',
        backgroundColor: 'white',
        logo: require('@/assets/brelmatter/img/logo.png'), 
        textColor: '#791b2c',
        inputStyle: 'round-border',
        inputBorderColor: '#979797',
        loginBtnBackgroundColor: "#791b2c",
        loginBtnBackgroundColorHover: '#944956',
        loginBtnBackgroundColorDisabled: '#d7bbc0',
      }
    };
  }
}
</script>