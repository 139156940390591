<template>
    <div class="bg-container" :style="{ backgroundColor: options.wallBackgroundColor }">
        <div class="main-container" :style="{ backgroundColor: options.backgroundColor }">
            <img class="logo" :src="options.logo" />
            <div class="content-container">
                <p class="tips tips-head" :style="{ color: options.textColor }">You are about to permanently delete your app
                    account (<strong>{{
                        delAccountParams.loginName
                    }}</strong>) and associated data.</p>
                <p class="tips" :style="{ color: options.textColor }">To confirm deletion, type <strong
                        class="strong-delete-words" :style="deleteWordsCss">Delete
                        me</strong> in the text entry
                    field.</p>
                <div class="sure-input-div" :style="inputCss">
                    <input class="sure-input" placeholder="Delete me" autocomplete="off" v-model="sureText" />
                </div>
                <div class="divider-line" v-if="options.inputStyle == 'underline'"></div>
                <div class="btns-toolbar">
                    <button class="yesno-btn cancel-btn" :style="cancelBtnCss" @click="doCancel">Cancel</button>
                    <button class="yesno-btn delete-btn" :disabled="delBtnDisabled" @click="doDelete"
                        tabindex="3">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
/*
采用3db配色作为默认配色
*/
import { delAccount, delToken } from '@/api/network';
import store from '@/store';

export default {
    props: {
        /*
        appName: 应用名称,
        themeColor: 主题色,
        wallBackgroundColor: 背景墙的背景颜色,
        backgroundColor: 内容的背景颜色,
        logo: logo地址,
        textColor: 提示文字的颜色,
        deleteWordsColor: 文字“Delete me”的颜色，默认使用themeColor，themColor不存在则使用#6ba4b8,
        inputStyle: 输入框的样式，可选underline|round｜round-border，默认round,
        inputBorder: 输入框的边框样式,
        inputBorderColor: 输入框的边框颜色，默认使用themeColor，themColor不存在则使用#979797,
        cancelBtnBackgroundColor: 取消按钮的背景颜色,
        cancelBtnBackgroundColorHover: 取消按钮的背景颜色（hover状态）,
        cancelBtnBackgroundImage: 取消按钮的背景图片,
        */
        options: Object,
    },
    data() {
        return {
            sureText: '',
        }
    },
    computed: {
        delBtnDisabled() {
            return !(this.sureText == 'Delete me')
        },
        cancelBtnCss() {
            return {
                '--background-color': this.options.cancelBtnBackgroundColor || '#6ba4b8',
                '--background-color-hover': this.options.cancelBtnBackgroundColorHover || '#a6c8d4',
                '--background-image': this.options.cancelBtnBackgroundImage,
            }
        },
        delAccountParams() {
            return store.getters.delAccountParams(this.options.appName) || {}
        },
        themeColor() {
            return this.options.themeColor || '#6ba4b8'
        },
        deleteWordsCss() {
            return {
                color: this.options.deleteWordsColor || this.options.themeColor,
            }
        },
        inputCss() {
            let styleObject = {}
            if (this.options.inputStyle == 'round-border') {
                if (this.options.border) {
                    styleObject.border = this.options.border
                } else {
                    styleObject.border = '1px solid ' + (this.options.inputBorderColor || this.options.themeColor || '#979797')
                }
            }
            return styleObject
        }
    },
    methods: {
        doDelete() {
            this.$showLoading({ themeColor: this.themeColor });
            delAccount(
                this.delAccountParams.loginName,
                this.delAccountParams.password,
                this.delAccountParams.appCode,
                store.getters.token(this.options.appName))
                .then(({ success, response }) => {
                    if (success) {
                        this.$store.commit('user/LOGOUT', this.options.appName);
                        this.$router.push({
                            path: '/' + this.options.appName + '/account-deletion/success',
                            query: { redirect_uri: this.$route.path }
                        });
                    } else {
                        this.$acomax_showReminder('retCode:' + response.retCode + "," + "retMsg:" + response.retMsg);
                    }
                })
                .catch(error => {
                    this.$acomax_showReminder(error)
                }).finally(() => {
                    this.$hideLoading();
                });
        },
        doCancel() {
            this.$showLoading({ themeColor: this.themeColor });
            delToken(store.getters.token(this.options.appName), true)
                .then(({ success, response }) => {
                    if (!success) {
                        this.$acomax_showReminder('retCode:' + response.retCode + "," + "retMsg:" + response.retMsg);
                    } else {
                        this.$store.commit('user/LOGOUT', this.options.appName);
                        // window.location.reload(); // 刷新页面
                        // 返回登陆页
                        this.$router.push({
                            path: '/' + this.options.appName + '/account-deletion/login',
                            query: { redirect_uri: this.$route.path }
                        });
                    }
                })
                .catch(error => {
                    this.$acomax_showReminder(error);
                })
                .finally(() => {
                    this.$hideLoading();
                });
        }
    }
}
</script>
  
<style lang="scss" scoped>
.bg-container {
    background: #f8f8f8;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100%;
    display: flex
}

.main-container {
    background: #f4f3ef;
    max-width: 480px;
    min-width: 240px;
    padding: 0;
    margin: 0 auto;
}

.logo {
    width: 100%;
    vertical-align: middle; // 取消图片底部留白
}

.content-container {
    padding: 2rem;
}

.tips-head {
    margin-top: 0rem;
}

.tips {
    font-family: SFProText-Bold;
    font-size: 1rem;
    color: #7e91ad;
    letter-spacing: -0.0106rem;
    line-height: 1.625rem;
    font-weight: 700;

    .strong-delete-words {
        color: #6ba4b8;
    }
}

.sure-input-div {
    margin: 4rem 0 0 0;
    padding: 0 1.5rem;

    height: 4rem;
    border-radius: 4rem;
    background-color: white;
    // border: 1px solid #979797;

    overflow: hidden;
    display: flex;
    flex-direction: row;

}

.sure-input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: white;

    font-family: SFProText-Bold;
    font-size: .875rem;
    color: #000000;
    letter-spacing: -0.0294rem;
    font-weight: 500;

    /* 调整光标颜色 */
    // caret-color: #484f62;
}

/* 单独这一行大概率失效 */
input::input-placeholder {
    color: #999;
}

/* 有些资料显示需要写，有些显示不需要，但是在编辑器webstorm中该属性不被识别 */
::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #999;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #999;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #999;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #999;
}

.divider-line {
    height: 1px;
    margin: 0 1.25rem;
    background-color: #efefef;
}

.btns-toolbar {
    margin: 4rem 0 2rem 0;
    height: 2.75rem;

    .yesno-btn {
        display: inline-block;
        width: 45%;
        height: 2.75rem;
        border-radius: 2.75rem;
        border: none;

        font-family: SFProText-Bold;
        font-size: 1.125rem;
        color: #ffffff;
        text-align: center;
        font-weight: 600;
    }

    .cancel-btn {
        float: left;
        background-image: var(--background-image);
        background-color: var(--background-color);
    }

    /* 40% */
    .cancel-btn:focus,
    .cancel-btn:hover {
        background-color: var(--background-color-hover);
    }

    .delete-btn {
        float: right;
        background-color: #f33f0c;
    }

    .delete-btn:focus,
    .delete-btn:hover {
        background-color: #f88c6d;
    }

    .delete-btn:disabled {
        background-color: #fdd9ce;
        cursor: not-allowed;
    }
}
</style>