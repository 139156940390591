<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'amigo',
        themeColor: '#007577',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/amigo/img/logo.png'), 
        inputStyle: 'round-border',
        cancelBtnBackgroundColor: "#007577",
        cancelBtnBackgroundColorHover: '#66acad',
      }
    };
  }
}
</script>