<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'mykrispol',
        appCode: '4fb4745b-792b-4024-bd0d-c5ef068408bd',
        themeColor: '#ff7400',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/mykrispol/img/logo.png'), 
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#ff7400",
        loginBtnBackgroundColorHover: '#ffac66',
        loginBtnBackgroundColorDisabled: '#ffe3cc',
      }
    };
  }
}
</script>