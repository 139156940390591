import axios from 'axios';

// create an axios instance
const service = axios.create({
  //   baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'http://localhost/api',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    // status:2xx
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
