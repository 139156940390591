import request from '@/utils/request';
import qs from 'qs'

const CODE_SUCCESS = 20000;
const CODE_INVALID_TOKEN = 20108;

export function login(username, password, appCode) {
  const params = {
    msgId: getMsgId(),
    loginName: username,
    password: password,
    appCode: appCode
  };
  return postForm(process.env.VUE_APP_CONN_SERVER_URL + '/userCenter/user/login', params);
}

export function delAccount(loginName, password, appCode, accessToken) {
  const params = {
    msgId: getMsgId(),
    loginName: loginName,
    password: password,
    appCode: appCode,
    accessToken: accessToken,
    delAllMsg: '1'
  };
  // console.log(params);
  // return Promise.resolve({ success: true, response: { retCode: CODE_SUCCESS } });
  return postForm(process.env.VUE_APP_CONN_SERVER_URL + '/userCenter/gdprService/accountDel', params);
}

// export function delToken(accessToken) {
//   const params = {
//     msgId: getMsgId(),
//     accessToken: accessToken,
//     msgTime: getMsgTime(),
//   };
//   return postForm(process.env.VUE_APP_CONN_OAUTH_SERVER_URL + '/oauthCenter/oauthService/unbind', params);
// }

export function delToken(accessToken, allowExpired) {
  const params = {
    msgId: getMsgId(),
    accessToken: accessToken,
    msgTime: getMsgTime(),
  };
  return postForm(process.env.VUE_APP_CONN_OAUTH_SERVER_URL + '/oauthCenter/oauthService/unbind', params)
    .then(({ success, response }) => {
      if (success || (response.retCode == CODE_INVALID_TOKEN && allowExpired)) {
        return Promise.resolve({ success: true, response });
      } else {
        return Promise.resolve({ success: false, response });
      }
    });
}

function postForm(url, params) {
  return request({
    url: url,
    method: 'post',
    data: qs.stringify(params)
  })
    .then(response => {
      if (response.retCode == CODE_SUCCESS) {
        return Promise.resolve({ success: true, response });
      } else {
        return Promise.resolve({ success: false, response });
        // Message.error(response.retMsg);
        // 只返回错误相关的数据
        // return Promise.reject({ retCode: response.retCode, retMsg: response.retMsg });
      }
    });
}


function getMsgId() {
  const time = new Date()
  return 'T'
    + time.getUTCFullYear()
    + add0(time.getUTCMonth() + 1)
    + add0(time.getUTCDate())
    + add0(time.getUTCHours())
    + add0(time.getUTCMinutes())
    + add0(time.getUTCSeconds())
    + add00(time.getUTCMilliseconds());
}
function getMsgTime() {
  const time = new Date()
  return time.getUTCFullYear()
    + '-'
    + add0(time.getUTCMonth() + 1)
    + '-'
    + add0(time.getUTCDate())
    + " "
    + add0(time.getUTCHours())
    + ':'
    + add0(time.getUTCMinutes())
    + ':'
    + add0(time.getUTCSeconds());
}

function add0(m) {
  return m < 10 ? '0' + m : m;
}

function add00(m) {
  return m < 100 ? '00' + m : m;
}

// console.log(getMsgTime())