<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'avosdim',
        themeColor: '#162f70',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/avosdim/img/logo.png'), 
        textColor: '#162f70',
        deleteWordsColor: '#ccc133',
        inputStyle: 'round-border',
        inputBorderColor: '#979797',
        cancelBtnBackgroundColor: "#162f70",
        cancelBtnBackgroundColorHover: '#7382a9',
      }
    };
  }
}
</script>