<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'eurotronic',
        appCode: 'c693efdf-c3c3-4893-b271-336149249d46',
        themeColor: '#004aad',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/eurotronic/img/logo.png'), 
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#004aad",
        loginBtnBackgroundColorHover: '#6692ce',
        loginBtnBackgroundColorDisabled: '#ccdbef',
      }
    };
  }
}
</script>