<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'riel-americano',
        appCode: '541dc92c-2e5a-4a3c-8857-50f0f7029540',
        themeColor: '#cc1f34',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/riel-americano/img/logo.png'),
        textColor: '#777777', 
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#cc1f34",
        loginBtnBackgroundColorHover: '#e07985',
        loginBtnBackgroundColorDisabled: '#f5d2d6',
      }
    };
  }
}
</script>