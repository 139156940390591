<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'moritz',
        themeColor: '#f50002',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/moritz/img/logo.png'), 
        inputStyle: 'round-border',
        inputBorderColor: '#979797',
        cancelBtnBackgroundColor: "#5b5b5b",
        cancelBtnBackgroundColorHover: '#9d9d9d',
      }
    };
  }
}
</script>