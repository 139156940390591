<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'wynmotion',
        appCode: '3742286d-39e2-42ca-b3f0-a26950f60d12',
        themeColor: '#efb1c9',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/wynmotion/img/logo.png'), 
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#efb1c9",
        loginBtnBackgroundColorHover: '#f4c8d9',
        loginBtnBackgroundColorDisabled: '#f9e0e9',
      }
    };
  }
}
</script>