<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'wellsun',
        themeColor: '#787878',
        backgroundColor: 'white',
        logo: require('@/assets/wellsun/img/logo.png'),
        textColor: '#787878',
        deleteWordsColor: '#ffc803', 
        inputStyle: 'round-border',
        cancelBtnBackgroundColor: "#787878",
        cancelBtnBackgroundColorHover: '#aeaeae',
      }
    };
  }
}
</script>