<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'knconnect',
        appCode: 'c62c8a8c-4b35-4783-9168-65b06a12a77d',
        themeColor: '#ec6607',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/knconnect/img/logo.png'), 
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#ec6607",
        loginBtnBackgroundColorHover: '#f4a36a',
        loginBtnBackgroundColorDisabled: '#fbe0cd',
      }
    };
  }
}
</script>