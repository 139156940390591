<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'gamana',
        themeColor: '#f5821f',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/gamana/img/logo.png'),
        textColor: '#606060', 
        inputStyle: 'round-border',
        inputBorderColor: '#606060',
        cancelBtnBackgroundColor: "#f5821f",
        cancelBtnBackgroundColorHover: '#f79b4c',
      }
    };
  }
}
</script>