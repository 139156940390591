<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'total-shade',
        themeColor: '#009A43',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/total-shade/img/logo.png'), 
        textColor: '#777777',
        inputStyle: 'round-border',
        // cancelBtnBackgroundColor: "#efb1c9",
        // cancelBtnBackgroundColorHover: '#f4c8d9',
        cancelBtnBackgroundColorDisabled: '#bbbbbb',
        cancelBtnBackgroundImage: 'linear-gradient(to bottom, #00ca78, #009a43)',
      }
    };
  }
}
</script>