<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'total-shade',
        appCode: '42f77c28-5b02-4689-ba98-0a91367cdac1',
        themeColor: '#009A43',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/total-shade/img/logo.png'), 
        textColor: '#777777',
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#efb1c9",
        loginBtnBackgroundColorHover: '#f4c8d9',
        loginBtnBackgroundColorDisabled: '#bbbbbb',
        loginBtnBackgroundImage: 'linear-gradient(to bottom, #00ca78, #009a43)',
      }
    };
  }
}
</script>