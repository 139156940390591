<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'ublock',
        appCode: 'cf9cded7-dc7e-4b7c-ab4b-e2184537acd7',
        themeColor: '#000000',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/ublock/img/logo.png'), 
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#000000",
        loginBtnBackgroundColorHover: '#4d4d4d',
        loginBtnBackgroundColorDisabled: '#cccccc',
      }
    };
  }
}
</script>