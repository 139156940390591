<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'acomax',
        appCode: '3714c233-0e7e-43e1-9123-c0f54bbbf75e',
        themeColor: '#e30613',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/acomax/img/logo.png'), 
        inputStyle: 'round-border',
        inputBorderColor: '#979797',
        loginBtnBackgroundColor: "#5b5b5b",
        loginBtnBackgroundColorHover: '#9d9d9d',
        loginBtnBackgroundColorDisabled: '#dedede',
      }
    };
  }
}
</script>