<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'levolor',
        appCode: 'ef9b5710-7141-4d36-a5f1-415f6a6cb944',
        themeColor: '#00bbe4',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/levolor/img/logo.png'), 
        textColor: '#1b3055',
        inputStyle: 'underline',
        loginBtnBackgroundColor: "#00bbe4",
        loginBtnBackgroundColorHover: '#66d6ef',
        loginBtnBackgroundColorDisabled: '#ccf1fa',
      }
    };
  }
}
</script>