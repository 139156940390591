<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'diaz',
        themeColor: '#64ccc9',
        backgroundColor: '#e9ecf0',
        logo: require('@/assets/diaz/img/logo.png'), 
        cancelBtnBackgroundColor: "#64ccc9",
        cancelBtnBackgroundColorHover: '#a2e0df',
      }
    };
  }
}
</script>