<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'penta-home',
        themeColor: '#5c514a',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/penta-home/img/logo.png'),
        textColor: '#5c514a',
        deleteWordsColor: '#d60029',
        inputStyle: 'round-border',
        cancelBtnBackgroundColor: "#353033",
        cancelBtnBackgroundColorHover: '#5d595c',
      }
    };
  }
}
</script>