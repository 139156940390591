<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'eurotronic',
        themeColor: '#004aad',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/eurotronic/img/logo.png'), 
        inputStyle: 'round-border',
        cancelBtnBackgroundColor: "#004aad",
        cancelBtnBackgroundColorHover: '#6692ce',
      }
    };
  }
}
</script>