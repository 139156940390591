<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'futurol',
        themeColor: '#ffab09',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/futurol/img/logo.png'), 
        textColor: '#565656',
        inputStyle: 'round-border',
        inputBorderColor: '#565656',
        cancelBtnBackgroundColor: "#ffab09",
        cancelBtnBackgroundColorHover: '#ffcd6b',
      }
    };
  }
}
</script>