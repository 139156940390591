<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'ublock',
        themeColor: '#000000',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/ublock/img/logo.png'), 
        inputStyle: 'round-border',
        cancelBtnBackgroundColor: "#000000",
        cancelBtnBackgroundColorHover: '#4d4d4d',
      }
    };
  }
}
</script>