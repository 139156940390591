<template>
    <div class="bg-container" :style="{ backgroundColor: options.wallBackgroundColor }">
        <div class="main-container" :style="{ backgroundColor: options.backgroundColor }">
            <img class="logo" :src="options.logo" />
            <el-form class="login-form" ref="loginForm" :model="loginForm">
                <div class="form-title" :style="{color: options.textColor}">If you want to permanently delete your app account and associated data.
                    Please enter your app account and password below to complete app account deletion.</div>
                <el-form-item class="username-form-item" prop="username">
                    <div class="username-form-item-wrap" :style="inputCss">
                        <input class="username-input login-input" v-model="loginForm.username"
                            placeholder="Username / Email" tabindex="1" />
                    </div>
                    <div class="divider-line" v-if="options.inputStyle == 'underline'"></div>
                </el-form-item>
                <el-form-item class="password-form-item" prop="password">
                    <div class="password-form-item-wrap" :style="inputCss">
                        <input class="password-input login-input" ref="password" v-model="loginForm.password"
                            placeholder="Password" :type=passwordType tabindex="2" />
                        <a class="show-password-btn" @click="showPwd">
                            <img src="@/assets/img/view.png" />
                        </a>
                    </div>
                    <div class="divider-line" v-if="options.inputStyle == 'underline'"></div>
                </el-form-item>
                <button class="login-btn" :style="loginBtnCss" type='button' :disabled="loginBtnDisabled" @click="handleLogin"
                    tabindex="3">Login</button>
            </el-form>
        </div>
    </div>
</template>
  
<script>
/*
采用3db配色作为默认配色
*/
import { login } from '@/api/network';
import { hex_md5 } from '@/utils/md5';

export default {
    props: {
        /*
        appName: 应用名称,
        appCode: 应用特征码
        themeColor: 主题色,
        wallBackgroundColor: 背景墙的背景颜色,
        backgroundColor: 内容的背景颜色,
        logo: logo地址,
        textColor: 提示文字的颜色,
        inputStyle: 输入框的样式，可选underline|round｜round-border，默认round,
        inputBorder: 输入框的边框样式,
        inputBorderColor: 输入框的边框颜色，默认使用themeColor，themColor不存在则使用#979797,
        loginBtnBackgroundColor: 登陆按钮的背景颜色,
        loginBtnBackgroundColorHover: 登陆按钮的背景颜色（hover/focus状态）,
        loginBtnBackgroundColorDisabled: 登陆按钮的背景颜色（disabled状态）,
        loginBtnBackgroundImage: 登陆按钮的背景图片,
        loginBtnBackgroundImageDisabled: 登陆按钮的背景颜色（disabled状态）,
        */
        options: Object
    },
    data() {
        return {
            loginForm: {
                username: '',
                password: '',
            },
            passwordType: 'password',
        };
    },
    computed: {
        loginBtnDisabled() {
            return !(this.loginForm.username.length > 0 && this.loginForm.password.length > 0);
        },
        loginBtnCss() {
            return {
                '--background-color': this.options.loginBtnBackgroundColor || '#6ba4b8',
                '--background-color-hover': this.options.loginBtnBackgroundColorHover || '#a6c8d4',
                '--background-color-focus': this.options.loginBtnBackgroundColorHover || '#a6c8d4',
                '--background-color-disabled': this.options.loginBtnBackgroundColorDisabled || '#d3e4ea',
                '--background-image': this.options.loginBtnBackgroundImage,
                '--background-image-disabled': this.options.loginBtnBackgroundImageDisabled,
            }
        },
        themeColor() {
            return this.options.themeColor || '#6ba4b8'
        },
        inputCss() {
            let styleObject = {}
            if (this.options.inputStyle == 'round-border') {
                if (this.options.border) {
                    styleObject.border = this.options.border
                } else {
                    styleObject.border = '1px solid ' + (this.options.inputBorderColor || this.options.themeColor || '#979797')
                }
            }
            return styleObject
        }
    },
    methods: {
        showPwd() {
            if (this.passwordType === 'password') {
                this.passwordType = '';
            } else {
                this.passwordType = 'password';
            }
            this.$nextTick(() => {
                this.$refs.password.focus();
            });
        },
        handleLogin() {
            const username = this.loginForm.username;
            const password = this.loginForm.password;
            if (username.length < 4) {
                this.$acomax_showReminder('The username can not be less than 4 digits');
                // this.$acomax_showReminder('The username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digitsThe username can not be less than 4 digits');
                return false;
            }
            if (password.length < 6) {
                this.$acomax_showReminder('The password can not be less than 6 digits');
                return false;
            }
            this.$showLoading({ themeColor: this.themeColor });
            const passwordHash = hex_md5(this.loginForm.password).toUpperCase();
            login(username, passwordHash, this.options.appCode)
                .then(({ success, response }) => {
                    if (success) {
                        const delAccountParams = {
                            loginName: username,
                            password: passwordHash,
                            appCode: this.options.appCode
                        };
                        this.$store.commit('user/SET_TOKEN', { appName: this.options.appName, token: response.accessToken });
                        this.$store.commit('user/SET_DEL_ACCOUNT_PARAMS', { appName: this.options.appName, params: delAccountParams });
                        this.$router.push({ path: this.$route.query.redirect_uri });
                    } else {
                        // 20104用户名或者密码错误，20114无效的用户名
                        if (response.retCode == 20104 || response.retCode == 20114) {
                            this.$acomax_showReminder('User does not exist or password is incorrect')
                        } else {
                            this.$acomax_showReminder('retCode:' + response.retCode + "," + "retMsg:" + response.retMsg);
                        }
                    }
                })
                .catch(error => {
                    this.$acomax_showReminder(error);
                }).finally(() => {
                    this.$hideLoading();
                });
        },
    },
};
</script>
  
<style lang="scss" scoped>
.bg-container {
    background: #f8f8f8;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100%;
    display: flex
}

.main-container {
    background: #f4f3ef;
    max-width: 480px;
    min-width: 240px;
    padding: 0;
    margin: 0 auto;
}

.logo {
    width: 100%;
    vertical-align: middle; // 取消图片底部留白
}

.login-form {
    widows: 100%;
    padding: 1.75rem;
    margin: 0 auto;
}

.form-title {
    font-family: SFProText-Bold;
    font-size: 1rem;
    color: #7e91ad;
    letter-spacing: -0.0106rem;
    line-height: 1.625rem;
    font-weight: 700;
    // background: yellow;
}

.username-form-item {
    margin: 3.125rem 0 0 0;

    .username-form-item-wrap {
        padding: 0 1.5rem;

        height: 4rem;
        border-radius: 4rem;
        background-color: white;
        // border: 1px solid #979797;

        overflow: hidden;
        display: flex;
        flex-direction: row;

        input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            background-color: white;
        }
    }
}

.password-form-item {
    margin: 1.75rem 0 0 0;

    .password-form-item-wrap {
        padding: 0 1.5rem;
        height: 4rem;
        border-radius: 4rem;
        background-color: white;
        // background-color: yellow;

        // border: 1px solid #979797;

        overflow: hidden;
        display: flex;
        flex-direction: row;

        input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            background-color: white;
        }

        .show-password-btn {
            // background-color: aquamarine;
            margin: auto 0;
            margin-right: -1.5rem;
            width: 2.5rem;
            height: 2.5rem;

            img {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
}

.login-input {
    font-family: SFProText-Medium;
    font-size: .875rem;
    font-weight: 500;

    /* 调整光标颜色 */
    // caret-color: #484F62;
    // caret-width: 3px;
}

/* 单独这一行大概率失效 */
input::input-placeholder {
    color: #999;
}

/* 有些资料显示需要写，有些显示不需要，但是在编辑器webstorm中该属性不被识别 */
::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #999;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #999;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #999;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #999;
}

.divider-line {
  height: 1px;
  margin: 0 1.25rem;
  background-color: #efefef;
}

.login-btn {
    margin: 3.125rem 0 2rem 0;
    width: 100%;
    height: 3rem;
    border-radius: 1.5rem;
    border: none;

    font-family: SFProText-Bold;
    font-size: 1.125rem;
    color: #ffffff;
    text-align: center;
    font-weight: 700;

    background-image: var(--background-image);
    background-color: var(--background-color);
}

/* 40% */
.login-btn:focus,
.login-btn:hover {
    background-color: var(--background-color-focus);
}

/* 70% */
.login-btn:disabled {
    cursor: not-allowed;
    background-image: var(--background-image-disabled);
    background-color: var(--background-color-disabled);
}
</style>
  