<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'brelhome',
        appCode: '6ed7697c-32c7-478f-ba0e-699308a269bf',
        themeColor: '#81142d',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/brelhome/img/logo.png'), 
        inputStyle: 'round-border',
        inputBorderColor: '#979797',
        loginBtnBackgroundColor: "#81142d",
        loginBtnBackgroundColorHover: '#b37281',
        loginBtnBackgroundColorDisabled: '#e6d0d5',
      }
    };
  }
}
</script>