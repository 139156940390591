<template>
  <DelAccountSuccess :options=viewOptions></DelAccountSuccess>
</template>

<script>
import DelAccountSuccess from '@/components/DelAccountSuccess'

export default {
  components: {
    DelAccountSuccess,
  },
  data() {
    return {
      viewOptions: {
        backgroundColor: '#ecf2f3',
        logo: require('@/assets/amp/img/logo.png'), 
      }
    };
  }
}
</script>