<template>
  <DelAccountSuccess :options=viewOptions></DelAccountSuccess>
</template>

<script>
import DelAccountSuccess from '@/components/DelAccountSuccess'

export default {
  components: {
    DelAccountSuccess,
  },
  data() {
    return {
      viewOptions: {
        backgroundColor: 'white',
        logo: require('@/assets/brelmatter/img/logo.png'),
        textColor: '#791b2c',
      }
    };
  }
}
</script>