<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'riel-americano',
        themeColor: '#cc1f34',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/riel-americano/img/logo.png'), 
        textColor: '#777777',
        inputStyle: 'round-border',
        cancelBtnBackgroundColor: "#cc1f34",
        cancelBtnBackgroundColorHover: '#e07985',
      }
    };
  }
}
</script>