<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'azzurra',
        themeColor: '#1e55b2',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/azzurra/img/logo.png'),
        textColor: '#606060', 
        inputStyle: 'round-border',
        cancelBtnBackgroundColor: "#1e55b2",
        cancelBtnBackgroundColorHover: '#4b77c1',
      }
    };
  }
}
</script>