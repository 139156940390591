<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'penta-home',
        appCode: 'c8bd0bcb-8b2d-4923-a75b-9947dc447959',
        themeColor: '#5c514a',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/penta-home/img/logo.png'), 
        textColor: '#5c514a',
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#353033",
        loginBtnBackgroundColorHover: '#5d595c',
        loginBtnBackgroundColorDisabled: '#c2c1c2',
      }
    };
  }
}
</script>