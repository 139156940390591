<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'smith',
        appCode: '5bfac3fb-85d3-45f7-8211-89aabd061684',
        themeColor: '#4aaec8',
        backgroundColor: '#e9ecf0',
        logo: require('@/assets/smith/img/logo.png'), 
        loginBtnBackgroundColor: "#4aaec8",
        loginBtnBackgroundColorHover: '#92cede',
        loginBtnBackgroundColorDisabled: '#c9e7ef',
      }
    };
  }
}
</script>