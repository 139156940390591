<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'gamana',
        appCode: '6b3ab09f-cfe1-4473-9fc2-178b0c693a2a',
        themeColor: '#f5821f',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/gamana/img/logo.png'), 
        textColor: '#606060',
        inputStyle: 'round-border',
        inputBorderColor: '#606060',
        loginBtnBackgroundColor: "#f5821f",
        loginBtnBackgroundColorHover: '#f79b4c',
        loginBtnBackgroundColorDisabled: '#fde6d2',
      }
    };
  }
}
</script>