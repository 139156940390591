<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'persol',
        themeColor: '#487466',
        backgroundColor: '#e2eee5',
        logo: require('@/assets/persol/img/logo.png'), 
        cancelBtnBackgroundColor: "#487466",
        cancelBtnBackgroundColorHover: '#91aca3',
      }
    };
  }
}
</script>