<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'diaz',
        appCode: 'f359d545-c14e-4a18-bd22-009c61514c1f',
        themeColor: '#64ccc9',
        backgroundColor: '#e9ecf0',
        logo: require('@/assets/diaz/img/logo.png'), 
        loginBtnBackgroundColor: "#64ccc9",
        loginBtnBackgroundColorHover: '#a2e0df',
        loginBtnBackgroundColorDisabled: '#c1ebe9',
      }
    };
  }
}
</script>