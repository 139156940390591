<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'brelmatter',
        themeColor: '#791b2c',
        backgroundColor: 'white',
        logo: require('@/assets/brelmatter/img/logo.png'),
        textColor: '#791b2c',
        deleteWordsColor: '#ec6c20', 
        inputStyle: 'round-border',
        inputBorderColor: '#979797',
        cancelBtnBackgroundColor: "#791b2c",
        cancelBtnBackgroundColorHover: '#944956',
      }
    };
  }
}
</script>