<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'amigo',
        appCode: 'b61c9f31-8bfb-4318-b6a5-e005b3e6f99e',
        themeColor: '#007577',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/amigo/img/logo.png'), 
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#007577",
        loginBtnBackgroundColorHover: '#66acad',
        loginBtnBackgroundColorDisabled: '#cce3e4',
      }
    };
  }
}
</script>