<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'levolor',
        themeColor: '#00bbe4',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/levolor/img/logo.png'), 
        deleteWordsColor: '#00add8',
        textColor: '#1b3055',
        inputStyle: 'underline',
        cancelBtnBackgroundColor: "#00bbe4",
        cancelBtnBackgroundColorHover: '#66d6ef',
      }
    };
  }
}
</script>