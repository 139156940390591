<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'enyroom',
        appCode: 'b05f2a83-88f0-4c80-9fb2-36c1426bdfbf',
        themeColor: '#9cb592',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/enyroom/img/logo.png'), 
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#9cb592",
        loginBtnBackgroundColorHover: '#bacbb3',
        loginBtnBackgroundColorDisabled: '#e1e9de',
      }
    };
  }
}
</script>