<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'avosdim',
        appCode: 'b534dd80-abd7-40da-b79f-c3da70701160',
        themeColor: '#162f70',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/avosdim/img/logo.png'),
        textColor: '#162f70',
        inputStyle: 'round-border',
        inputBorderColor: '#979797',
        loginBtnBackgroundColor: "#162f70",
        loginBtnBackgroundColorHover: '#7382a9',
        loginBtnBackgroundColorDisabled: '#d0d5e2',
      }
    };
  }
}
</script>