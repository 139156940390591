<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'amp',
        appCode: 'ae4ded8a-9df0-4f6a-87dc-c3ff58413b63',
        themeColor: '#00bbe4',
        backgroundColor: '#ecf2f3',
        logo: require('@/assets/amp/img/logo.png'), 
        loginBtnBackgroundColor: "#00bbe4",
        loginBtnBackgroundColorHover: '#66d6ef',
        loginBtnBackgroundColorDisabled: '#b3ebf7',
      }
    };
  }
}
</script>