<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'bliss',
        appCode: '892ad32a-35f5-4b06-b1ec-accd1cbf8e24',
        themeColor: '#4c86a0',
        backgroundColor: '#efeeeb',
        logo: require('@/assets/bliss/img/logo.png'), 
        loginBtnBackgroundColor: "#4c86a0",
        loginBtnBackgroundColorHover: '#94b6c6',
        loginBtnBackgroundColorDisabled: '#c9dbe3',
      }
    };
  }
}
</script>