<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'azzurra',
        appCode: '09ba3285-bc02-4d2a-abb2-ce2a26939044',
        themeColor: '#1e55b2',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/azzurra/img/logo.png'), 
        textColor: '#606060',
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#1e55b2",
        loginBtnBackgroundColorHover: '#4b77c1',
        loginBtnBackgroundColorDisabled: '#bccce8',
      }
    };
  }
}
</script>