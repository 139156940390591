<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'wellsun',
        appCode: '3b4f91d8-feb0-477c-b701-5967bb92bba1',
        themeColor: '#787878',
        backgroundColor: 'white',
        logo: require('@/assets/wellsun/img/logo.png'), 
        textColor: '#787878',
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#787878",
        loginBtnBackgroundColorHover: '#aeaeae',
        loginBtnBackgroundColorDisabled: '#e4e4e4',
      }
    };
  }
}
</script>