<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'smith',
        themeColor: '#4aaec8',
        backgroundColor: '#e9ecf0',
        logo: require('@/assets/smith/img/logo.png'), 
        cancelBtnBackgroundColor: "#4aaec8",
        cancelBtnBackgroundColorHover: '#92cede',
      }
    };
  }
}
</script>