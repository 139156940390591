<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'moritz',
        appCode: '92c9c09a-b7b5-4c6c-bbb9-028b761763d9',
        themeColor: '#f50002',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/moritz/img/logo.png'),
        inputStyle: 'round-border',
        inputBorderColor: '#979797',
        loginBtnBackgroundColor: "#f50002",
        loginBtnBackgroundColorHover: '#f96667',
        loginBtnBackgroundColorDisabled: '#fdcccc',
      }
    };
  }
}
</script>