<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'amp',
        themeColor: '#00bbe4',
        backgroundColor: '#ecf2f3',
        logo: require('@/assets/amp/img/logo.png'), 
        cancelBtnBackgroundColor: "#00bbe4",
        cancelBtnBackgroundColorHover: '#66d6ef',
      }
    };
  }
}
</script>