<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'gaviota',
        appCode: '9671688a-9c31-48f5-8868-cd4b9667a3cb',
        themeColor: '#484f62',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/gaviota/img/logo.png'), 
        inputStyle: 'round-border',
        inputBorderColor: '#979797',
        loginBtnBackgroundColor: "#484f62",
        loginBtnBackgroundColorHover: '#9195a1',
        loginBtnBackgroundColorDisabled: '#dadce0',
      }
    };
  }
}
</script>