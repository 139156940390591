<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'bliss',
        themeColor: '#4c86a0',
        backgroundColor: '#efeeeb',
        logo: require('@/assets/bliss/img/logo.png'), 
        deleteWordsColor: '#1897b3',
        cancelBtnBackgroundColor: "#4c86a0",
        cancelBtnBackgroundColorHover: '#94b6c6',
      }
    };
  }
}
</script>