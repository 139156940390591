<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'mamaba',
        appCode: '92c9c09a-b7b5-4c6c-bbb9-028b761763d9',
        themeColor: '#333333',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/mamaba/img/logo.png'), 
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#333333",
        loginBtnBackgroundColorHover: '#858585',
        loginBtnBackgroundColorDisabled: '#d6d6d6',
      }
    };
  }
}
</script>