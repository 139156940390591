<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: '3db',
        themeColor: '#6ba4b8',
        backgroundColor: '#f4f3ef',
        logo: require('@/assets/3db/img/logo.png'), 
        cancelBtnBackgroundColor: "#6ba4b8",
        cancelBtnBackgroundColorHover: '#a6c8d4',
      }
    };
  }
}
</script>