<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: '3db',
        appCode: '1238fe51-979e-4336-a610-146d445aa879',
        themeColor: '#6ba4b8',
        backgroundColor: '#f4f3ef',
        logo: require('@/assets/3db/img/logo.png'), 
        loginBtnBackgroundColor: "#6ba4b8",
        loginBtnBackgroundColorHover: '#a6c8d4',
        loginBtnBackgroundColorDisabled: '#d3e4ea',
      }
    };
  }
}
</script>