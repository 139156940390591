<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'acomax',
        themeColor: '#e30613',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/acomax/img/logo.png'), 
        inputStyle: 'round-border',
        inputBorderColor: '#979797',
        cancelBtnBackgroundColor: "#5b5b5b",
        cancelBtnBackgroundColorHover: '#9d9d9d',
      }
    };
  }
}
</script>