<template>
  <DelAccount :options=viewOptions></DelAccount>
</template>

<script>
import DelAccount from '@/components/DelAccount'

export default {
  components: {
    DelAccount,
  },
  data() {
    return {
      viewOptions: {
        appName: 'mamaba',
        themeColor: '#333333',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/mamaba/img/logo.png'), 
        inputStyle: 'round-border',
        cancelBtnBackgroundColor: "#333333",
        cancelBtnBackgroundColorHover: '#858585',
      }
    };
  }
}
</script>