<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'bloc-blinds',
        appCode: '92c9c09a-b7b5-4c6c-bbb9-028b761763d9',
        themeColor: '#438af3',
        wallBackgroundColor: '#eeeeee',
        backgroundColor: 'white',
        logo: require('@/assets/bloc-blinds/img/logo.png'), 
        inputStyle: 'round-border',
        loginBtnBackgroundColor: "#438af3",
        loginBtnBackgroundColorHover: '#8eb9f8',
        loginBtnBackgroundColorDisabled: '#d9e8fd',
      }
    };
  }
}
</script>