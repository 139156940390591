<template>
  <Login :options=viewOptions></Login>
</template>

<script>
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      viewOptions: {
        appName: 'persol',
        appCode: 'c6e54217-c2f7-4d95-bbae-63b2d85b9f6e',
        themeColor: '#487466',
        backgroundColor: '#e2eee5',
        logo: require('@/assets/persol/img/logo.png'), 
        loginBtnBackgroundColor: "#487466",
        loginBtnBackgroundColorHover: '#91aca3',
        loginBtnBackgroundColorDisabled: '#dae3e0',
      }
    };
  }
}
</script>